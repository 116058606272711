import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, Divider } from '@mui/material';

export default function Footer() {
    return (
        <Box sx={{ backgroundColor: '#131417', color: '#fff', padding: '20px 0', mt: 4 }}>
            <Grid container justifyContent="center">
                <Grid item xs={11} sm={10} md={8}>
                    <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                        
                        {/* Left Section */}
                        <Grid item xs={12} md={4} sx={{ textAlign: {xs: 'center', md: 'left'} }}>
                            <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                © 2024. All rights reserved.
                            </Typography>
                        </Grid>
                        
                        {/* Center Section */}
                        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 400 }}>
                                D.J. TURNER ELECTRICAL SERVICES LTD
                            </Typography>
                        </Grid>
                        
                        {/* Right Section */}
                        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                Company number 12505330
                            </Typography>
                        </Grid>
                    </Grid>
                    
                    {/* Divider */}
                    <Box sx={{ mt: 3 }}>
                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                    </Box>

                    {/* Additional Information (Optional) */}
                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                            Website designed by Louis Groombridge-Wright | Privacy Policy | Terms of Service
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
