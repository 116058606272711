import * as React from 'react';
import { Box, Button, Hidden } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function CallNowBanner() {
    return (
        <Hidden mdUp>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: '15px',
                    right: '15px',
                    zIndex: 1000, // Ensures it stays on top of other elements
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant='outlined'
                    href="https://wa.me/+447886300331" 
                    sx={{
                        backgroundColor: '#47cf73',
                        color: '#000',
                        borderColor: '#47cf73',
                        borderRadius: 40,
                        '&:hover': {
                            backgroundColor: '#fff',  // Change to white on hover
                            color: '#000',            // Change text color to black on hover
                            borderColor: '#000',      // Change border color to black on hover
                        },
                        height: 45
                    }}
                >
                    <WhatsAppIcon sx={{marginRight: 1}}/>Contact Now
                </Button>
            </Box>
        </Hidden>
        
    );
}
