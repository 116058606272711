import { Button } from '@mui/material';
import * as React from 'react';
import { Dialog, DialogActions, DialogContent, Grid, TextField , DialogTitle} from '@mui/material';
import GoogleMaps from './google_maps_autocomplete';
import reCAPTCHA from "react-google-recaptcha";

export default function SquareButton(props) {
    const [open, setOpen] = React.useState(false);
    const [formItems, setFormItems] = React.useState({
        name: '',
        phone_number: '',
        email: '',
        address: '',
        // captcha: ''
    });
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const captchaRef = React.useRef(null);

    function toggleOpen() {
        setOpen(!open);
        setValue('');
    }

    const handleInputChange = (item) => (event) => {
        setFormItems((prevItems) => ({
            ...prevItems,
            [item]: event.target.value,
        }));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        formItems.address = value ? value.description : ''; // Set the address from GoogleMaps autocomplete
        // const token = captchaRef.current.getValue();
        // formItems.captcha = token;

        // Send form data to Formspree
        try {
            const response = await fetch('https://formspree.io/f/meojydky', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formItems),
            });

            if (response.ok) {
                alert("Form submitted successfully!");
                toggleOpen(); // Close dialog after submission
            } else {
                alert("Error submitting form");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("Error submitting form");
        }
    };

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                {...props}
                sx={{
                    backgroundColor: '#47cf73',
                    color: '#000',
                    borderColor: '#47cf73',
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: '#000',
                        borderColor: '#000',
                    },
                    height: props.height,
                }}
                onClick={() => toggleOpen()}
            >
                {props.children}
            </Button>

            <Dialog open={open} onClose={toggleOpen} fullWidth>
                <DialogTitle>Get a no obligation quote today</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container sx={{ flex: 1 }}>
                            <Grid item xs={12} p={1}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Full Name"
                                    value={formItems.name}
                                    onChange={handleInputChange('name')}
                                />
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Email Address"
                                    value={formItems.email}
                                    onChange={handleInputChange('email')}
                                />
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Phone Number"
                                    value={formItems.phone_number}
                                    onChange={handleInputChange('phone_number')}
                                />
                            </Grid>
                            <Grid item xs={12} p={1}>
                                <GoogleMaps
                                    value={value}
                                    setValue={setValue}
                                    options={options}
                                    setOptions={setOptions}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                />
                            </Grid>
                            <Grid item xs={12} p={1}>
                                {/* <reCAPTCHA sitekey="6LceDjIqAAAAAMKKN3J1p6fHs63TAFqkXeV3bQBh" ref={captchaRef} /> */}
                            </Grid>
                            <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button type="submit" variant="contained" disableElevation>
                                    Request quotation
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={toggleOpen} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
