import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import SquareButton from '../components/square-button';
import {Hidden} from '@mui/material';

export default function Header() {
    return (
        <React.Fragment>
            <Grid 
                container  
                sx={{
                    height: 120, 
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', // Space between the logo/text and button
                    display: 'flex', 
                    borderBottom: 1, 
                    borderBottomColor: 'rgba(102, 102, 102, 0.7)', 
                    boxShadow: 5, 
                    paddingLeft: 2,
                    paddingRight: 2,
                    flexDirection: 'row',
                    position: 'fixed',
                    zIndex: 4
                }}
            >   
                <Grid item xs={12} md="auto" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box 
                        sx={{
                            backgroundImage: 'url("/images/dj-turner-logo-no-background.png")',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            height: '110px',  // adjust the height to suit your image
                            width: '110px',   // optional width adjustment for the image
                            marginRight: 2,   // space between the image and text
                        }}
                    >
                    </Box>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant='h4' color={'#FFF'}>
                            D.J TURNER
                        </Typography>
                        <Typography variant='h5' color={'#FFF'}>
                            ELECTRICAL SERVICES
                        </Typography>
                    </Box>
                </Grid>
                
                <Hidden mdDown>
                <Grid item xs={12} md="auto" sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: 2 }}>
                    <SquareButton height={45}>Contact Us</SquareButton>
                </Grid>
                </Hidden>

            </Grid>
        </React.Fragment>
    )
}
