import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, Divider } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import SquareButton from '../components/square-button';
import LightIcon from '@mui/icons-material/Light';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export default function QuoteFlow() {

    return (
        <Fade delay={100}  cascade={true} fraction={0} damping={0.5}>
            <Grid container p={{md: 3 , xs: 1}}  sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10, textAlign: 'center' }}>
                <Grid item xs={12}>
                    <Typography variant='h4'>
                        WHY CHOOSE US
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 3 }}>
                    <Typography variant='h3'>
                        WE TAKE CARE OF EVERYTHING FOR YOU
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: {md: 10, xs: 5} }}>
                    <Grid container p={1} sx={{ display: 'flex', flex: 1, justifyContent: 'space-evenly', alignItems: 'stretch' }}>
                        <Grid item xs={12} md={4} p={3}>
                            <Grid container rowGap={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h4'>INITIAL CONSULTATION</Typography>
                                <Box sx={{ height: '120px', width: '120px', backgroundColor: '#47cf73', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                                    <PersonIcon sx={{ fontSize: 90 }} />
                                </Box>
                                <Typography variant='h6'>We'll discuss your project needs, either over the phone or in person, to understand your requirements and provide expert advice.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} p={3}>
                            <Grid container rowGap={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h4'>SITE VISIT</Typography>
                                <Box sx={{ height: '120px', width: '120px', backgroundColor: '#47cf73', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                                    <HomeIcon sx={{ fontSize: 90 }} />
                                </Box>
                                <Typography variant='h6'>A qualified electrician will visit your property to assess the scope of work, taking precise measurements and identifying any specific challenges or considerations.</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} p={3}>
                            <Grid container rowGap={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h4'>DETAILED QUOTE</Typography>
                                <Box sx={{ height: '120px', width: '120px', backgroundColor: '#47cf73', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                                    <RequestQuoteIcon sx={{ fontSize: 90 }} />
                                </Box>
                                <Typography variant='h6'>We'll provide you with a comprehensive, no-obligation quote that outlines the costs and timelines, ensuring transparency and clarity before work begins.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 5 }}>
                    <SquareButton height={45}>Contact Us</SquareButton>
                </Grid>
            </Grid>
        </Fade>

    )
}