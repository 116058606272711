import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, Divider } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import SquareButton from '../components/square-button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FaqAccordion() {

    return (
        <Fade delay={100} cascade={true} fraction={0.2} damping={0.5}>
            <Grid container p={3} pl={{md: 5, xs: 3}} pr={{md: 5, xs: 3}} sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 5, marginBottom: 5, textAlign: 'left' }}>
                <Grid item xs={12} md={6} pr={{md: 3, xs: 0}}>
                    <Typography variant='h5' sx={{ marginBottom: 3 }}>
                        FAQ
                    </Typography>
                    <Typography variant='h3' sx={{ marginBottom: 3 }}>
                        FREQUENTLY ASKED QUESTIONS
                    </Typography>
                    <Typography variant='h6' sx={{marginBottom: 3}}>
                        We understand that you might have questions about our services, pricing, and procedures. To make things easier, we've compiled a list of the most commonly asked questions from our clients. Whether you're curious about our service areas, want to know how we handle emergencies, or are interested in the details of our pricing, you'll find the answers here. Simply click on each question to reveal the answer, and if you need more information, don't hesitate to reach out to us directly!
                    </Typography>

                </Grid>
                <Grid item xs={12} md={6} pl={{md: 3, xs: 0}} sx={{ marginTop: 3, justifyContent: 'center', alignItems: 'middle' }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{paddingTop: 1, paddingBottom: 1}}
                        >
                            <Typography variant="h6">What services do you offer?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We offer a wide range of electrical services including residential, commercial, and industrial installations, repairs and smart home setup.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 2 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            sx={{paddingTop: 1, paddingBottom: 1}}
                        >
                            <Typography variant="h6">What are your service areas?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We provide services across several regions including Sutton, Carshalton and Croydon. If you are outside these areas, please contact us to discuss how we can help.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 3 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                            sx={{paddingTop: 1, paddingBottom: 1}}
                        >
                            <Typography variant="h6">How do you determine pricing?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our pricing is based on the scope of work, materials required, and time involved. We provide transparent, upfront quotes after an initial consultation to ensure there are no surprises.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 4 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                            sx={{paddingTop: 1, paddingBottom: 1}}
                        >
                            <Typography variant="h6">What should I do in an electrical emergency?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                In case of an electrical emergency, such as a power outage or sparking wires, immediately turn off the power at the main circuit breaker and contact us on 07886 300 331. 
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 5 */}
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                            sx={{paddingTop: 1, paddingBottom: 1}}
                        >
                            <Typography variant="h6">Are your electricians licensed and insured?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, all of our electricians are fully licensed, insured, and continuously trained to ensure they are up-to-date with the latest industry standards and safety protocols.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6a-content"
                            id="panel6a-header"
                            sx={{paddingTop: 1, paddingBottom: 1}}
                        >
                            <Typography variant="h6">Do you offer any warranties on your work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, we stand behind our work with comprehensive warranties. We offer a 1-year warranty on parts, and 3-years on labour, ensuring peace of mind for our clients. For more details, please contact us.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} pt={4} sx={{display: 'flex', justifyContent: {md: 'left', xs: 'center'}}}>
                <SquareButton height={45}>GET A QUOTE</SquareButton>
                </Grid>
            </Grid>
        </Fade>

    )
}