import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { ElfsightWidget } from 'react-elfsight-widget';

export default function Instagram() {

    return (
        <Grid container sx={{ flex: 1, textAlign: 'center' }}>
            <Grid item xs={12} p={4} sx={{ backgroundColor: '#131417', position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1, // Below the grid items but above the background
                    backgroundImage: `
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px)
                    `,
                    backgroundSize: '20% 100%', // Four evenly spaced lines
                }}
            />
                <Typography variant='h3' color={'#FFF'} xs={{zindex: 10, position: 'relative'}}>
                    CHECK OUT OUR INSTAGRAM
                </Typography>
            </Grid>
            <Grid item xs={12} pt={2} pb={2}>
                <ElfsightWidget widgetId='244eaa45-1956-434a-adbd-5ae7bc8d7380' />
            </Grid>

        </Grid>
    )
}