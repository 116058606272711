import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, Divider, Hidden } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import { MasonryPhotoAlbum } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "react-photo-album/masonry.css";
import SquareButton from '../components/square-button';
import photos from './test-photos';

const photos_v3 = [
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.43.09 copy.jpeg",
        "width": 963,
        "height": 581
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.49.47.jpeg",
        "width": 1124,
        "height": 1111
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.43.09.jpeg",
        "width": 1440,
        "height": 882
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.43.08.jpeg",
        "width": 960,
        "height": 720
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.49.44.jpeg",
        "width": 1124,
        "height": 1133
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.43.11 (1).jpeg",
        "width": 1124,
        "height": 1118
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.49.43 (1).jpeg",
        "width": 1019,
        "height": 477
    },
    {
        "src": "/images/gallery/WhatsApp Image 2024-09-10 at 18.43.12 (1).jpeg",
        "width": 1600,
        "height": 706
    }
];

export default function Gallery() {

    return (
        <React.Fragment>
            <Box p={{md: 4, xs: 1}} sx={{ backgroundColor: '#131417', position: 'relative' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1, // Below the grid items but above the background
                        backgroundImage: `
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px)
                    `,
                        backgroundSize: '20% 100%', // Four evenly spaced lines
                    }}
                />

                <Grid container sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 6, color: '#FFF', textAlign: 'center', zIndex: 2, position: 'relative' }}>
                    <Grid item xs={12} sx={{ marginBottom: {md: 10, xs: 6} }}>
                        <Typography variant='h2'>
                            EXPLORE SOME OF OUR WORK
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ zIndex: 3, position: 'relative' }}>
                    
                    <Hidden mdDown>
                    <MasonryPhotoAlbum columns={3} photos={photos_v3} />
                    </Hidden>
                    <Hidden mdUp>
                    <MasonryPhotoAlbum columns={2} photos={photos_v3} />
                    </Hidden>
                </Box>
                <Grid container sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 6, color: '#FFF', textAlign: 'center', zIndex: 2, position: 'relative' }}>
                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                    <SquareButton height={45}>Contact Us</SquareButton>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}