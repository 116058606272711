import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box, Divider } from '@mui/material';
import KitchenIcon from '@mui/icons-material/Kitchen';
import RoofingIcon from '@mui/icons-material/Roofing';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import { Fade } from 'react-awesome-reveal';
import SquareButton from '../components/square-button';
import LightboxButton from '../components/lightbox-button';

export default function Services() {
    return (
        <Grid container p={4} pt={15} sx={{ position: 'relative', flex: 1, display: 'flex', backgroundColor: '#131417', marginTop: 8, color: '#FFF', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1, // Below the grid items but above the background
                    backgroundImage: `
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px),
                        linear-gradient(to right, rgba(102, 102, 102, 0.3) 1px, transparent 1px)
                    `,
                    backgroundSize: '20% 100%', // Four evenly spaced lines
                }}
            />
            <Grid item xs={12} sx={{ zIndex: 2 }}>
                <Typography variant='h4'>
                    SERVICES
                </Typography>
                <Typography variant='h3' sx={{ marginTop: 3, marginBottom: 10 }}>
                    WHAT WE DO
                </Typography>
            </Grid>
            <Fade cascade={true} delay={200} fraction={0} style={{ zIndex: 3 }}>
                <Grid item xs={12} sx={{ flex: 1 }}>
                    <Grid container rowSpacing={4} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'stretch' }}>
                        <Grid item xs={12} md={3} p={{md: 2, xs: 1}} sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1, zIndex: 3, position: 'relative' }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        01
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <KitchenIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        KITCHEN
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We provide expert kitchen electrical services, including appliance installation, lighting, and circuit upgrades, ensuring safety and efficiency in every kitchen.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='kitchen'  photos='kitchen'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={3}  p={{md: 2, xs: 1}}  sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        02
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <RoofingIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        EXTENSION
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We specialise in electrical services for extensions, covering everything from wiring to lighting, ensuring your new space is fully functional and up to standard.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Extension'  photos='extension'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}  p={{md: 2, xs: 1}}  sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        03
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <ElectricalServicesIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        FULL REWIRE'S
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We provide comprehensive full rewire services, ensuring your entire property is safely and efficiently updated to meet current electrical standards.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Full Rewires'  photos='full-rewires'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}  p={{md: 2, xs: 1}} sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        04
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <TroubleshootIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        FAULT FINDING
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We offer expert fault finding services, quickly diagnosing and resolving electrical issues to keep your home safe and functioning smoothly.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Fault Finding'  photos='fault-finding'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} md={3} p={{md: 2, xs: 1}}  sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        05
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <NetworkWifiIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        DATA & AERIAL
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We provide reliable data cabling, aerial and network installation services, ensuring fast and secure connectivity throughout your home or business.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Data & Aerial'  photos='data-aerial'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={3}  p={{md: 2, xs: 1}}  sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        06
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <HeatPumpIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        BOILER WIRING
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We offer professional boiler wiring services, ensuring safe, efficient, and reliable operation of your heating system. 
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Boiler Wiring'  photos='boiler-wiring'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}  p={{md: 2, xs: 1}}  sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        07
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <WbTwilightIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        GARDEN LIGHTING
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>

                                        We specialise in garden lighting solutions, enhancing your outdoor spaces with expertly designed and installed lighting that adds both beauty and security.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Garden Lighting' photos='garden-lighting'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} p={{md: 2, xs: 1}}  sx={{ display: 'flex' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', borderColor: '#fff', borderStyle: 'solid', borderWidth: 2, borderRadius: 3, backgroundColor: '#2c303a', flexGrow: 1 }}>
                                <Grid item xs={12} p={1.5} sx={{ textAlign: 'left' }}>
                                    <Typography variant='h6'>
                                        08
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <WbIridescentIcon sx={{ fontSize: 70 }} />
                                </Grid>
                                <Grid item xs={12} p={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='h4'>
                                        LUXURY LIGHTING
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <Divider variant='middle' sx={{ borderColor: 'rgba(255, 255, 255, 0.6)' }} />
                                </Grid>

                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography variant='body1'>
                                        We specialise in high-end lighting systems, delivering sophisticated, custom-designed solutions that elevate the ambiance and style of your home.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} p={1} pl={2} pr={2} pb={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <LightboxButton title='Luxury Lighting' photos='luxury-lighting'>
                                        Gallery
                                    </LightboxButton>
                                </Grid>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Fade>
            <Grid item xs={12} p={4} sx={{ zIndex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SquareButton height={45}>GET A QUOTE</SquareButton>
            </Grid>
        </Grid>
    );
}
