import * as React from 'react';
import Grid from '@mui/material/Grid'; // Grid version 1
import Header from '../sections/header';
import Hero from '../sections/hero';
import Experience from '../sections/experience';
import Accreditations from '../sections/accrediations';
import Services from '../sections/services';
import QuoteFlow from '../sections/quote-flow';
import Gallery from '../sections/gallery';
import Footer from '../sections/footer';
import FaqAccordion from '../sections/faq-accordion';
import Instagram from '../sections/instagram';
import CallNowBanner from '../sections/call-now-banner';

export default function Home() {
    return (
        <React.Fragment>
            <Header/>
            <Hero/>
            <Accreditations/>
            <Experience/>
            <Services/>
            <QuoteFlow/>
            <Gallery/>
            <FaqAccordion/>
            <Instagram/>
            <Footer/>
            <CallNowBanner/>
        </React.Fragment>
    );
}

