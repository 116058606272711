import * as React from 'react';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

import Home from './pages/home';

let theme = createTheme({
  typography: {
    fontFamily: [
      'sora',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 800
    },
    h2: {
      fontWeight: 600
    },
    h3: {
      fontWeight: 500
    },
    h4: {
      fontWeight: 400
    },
    h5: {
      fontWeight: 300
    },
    h6: {
      fontWeight: 300
    }
  },
});

theme = responsiveFontSizes(theme);

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <Home />
    </ThemeProvider>

  );
}

