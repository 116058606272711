import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@mui/material'
import SquareButton from '../components/square-button';
import { Fade } from 'react-awesome-reveal'

export default function Hero() {
    const [scale, setScale] = useState(1);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPos = window.scrollY;

            // Use requestAnimationFrame for better performance
            requestAnimationFrame(() => {
                // Calculate a scaling factor for the background image
                const newScale = 1 + scrollPos / 1000;
                setScale(newScale);
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Grid
            container
            sx={{
                flex: 1,
                display: 'flex',
                height: 'calc(100vh)',
                color: '#FFF',
                overflow: 'hidden',
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    position: 'relative',
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
                {/* Wrapper for scaling the background */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        transform: `scale(${scale})`,
                        transformOrigin: 'center center',
                        transition: 'transform 0.1s ease-out',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: 'url("/images/hero-image.jpeg")',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    />
                </Box>

                {/* Dark Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Adjust the opacity to make the background darker
                    }}
                />

                {/* Grid Lines */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 2,
                        backgroundImage: `
                            linear-gradient(to right, rgba(102, 102, 102, 0.3) 0.5px, transparent 1px),
                            linear-gradient(to right, rgba(102, 102, 102, 0.3) 0.5px, transparent 1px),
                            linear-gradient(to right, rgba(102, 102, 102, 0.3) 0.5px, transparent 1px),
                            linear-gradient(to right, rgba(102, 102, 102, 0.3) 0.5px, transparent 1px)
                        `,
                        backgroundSize: '20% 100%',
                    }}
                />

                <Grid container sx={{ zIndex: 3, height: '100%', position: 'relative', flexDirection: { xs: 'row' } }}>
                    {/* Move elements down */}
                    <Grid item xs={12} sx={{ height: '60%' }} />
                    <Grid item xs={12} sx={{ paddingLeft: '10%' }}>
                        <Fade delay={150} cascade={true} duration={1700}>
                        <Typography variant='h6' color={'#FFF'}>
                            INTRODUCING
                        </Typography>
                        <Grid container sx={{ marginTop: 3 }}>
                            <Grid item xs={12}>
                                <Typography variant='h3' color={'#FFF'}>
                                    SMALL BUT MIGHTY
                                </Typography>
                                <Typography variant='h2' color={'#47cf73'}>
                                    ELECTRICAL
                                </Typography>
                                <Typography variant='h3' color={'#FFF'}>
                                    SOLUTIONS
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: 4 }}>
                                <SquareButton height={45}>Contact Us</SquareButton>
                            </Grid>
                        </Grid>
                        </Fade>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
