import { Button } from '@mui/material';
import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import photos from '../sections/test-photos';

const photos_v2 = {
    "garden-lighting": [
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.48.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.46.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.41 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.47 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.42.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.45 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.46 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.42 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.47.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.45.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.40.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.40 (2).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.43.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.44.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.48 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.49.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.43 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.50.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.51.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.40 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.49 (1).jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.41.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.39.jpeg"
        },
        {
            "src": "/images/garden-lighting/WhatsApp Image 2024-09-10 at 18.49.39 (1).jpeg"
        }
    ],
    "luxury-lighting": [
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.12.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.15 (1).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.16.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.14 (2).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.20 (1).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.17 (1).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.14 (1).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.18.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.14.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.17.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.12 (1).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.18 (1).jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.13.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.15.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.11.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.19.jpeg"
        },
        {
            "src": "/images/luxury-lighting/WhatsApp Image 2024-09-10 at 18.53.20.jpeg"
        }
    ],
    "fault-finding": [
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (6).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (2).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (3).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (8).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (4).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13.jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (7).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (1).jpeg"
        },
        {
            "src": "/images/fault-finding/WhatsApp Image 2024-09-10 at 18.56.13 (5).jpeg"
        }
    ],
    "extension": [
        {
            "src": "/images/extension/WhatsApp Image 2024-09-10 at 18.43.09.jpeg"
        }
    ],
    "full-rewires": [
        {
            "src": "/images/full-rewires/WhatsApp Image 2024-09-10 at 18.36.56.jpeg"
        }
    ],
    "kitchen": [
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.07 (1).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.05 (1).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.10.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.07.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.05.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.09.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.08.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.08 (1).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.06.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.09 (1).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.11 (1).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.11.jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.12 (1).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.05 (2).jpeg"
        },
        {
            "src": "/images/kitchen/WhatsApp Image 2024-09-10 at 18.43.12.jpeg"
        }
    ],
    "boiler-wiring": [
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.52.jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.53.jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.49 (1).jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.50 (1).jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.51.jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.53 (1).jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.49 (2).jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.55 (1).jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.53 (2).jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.50.jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.49.jpeg"
        },
        {
            "src": "/images/boiler-wiring/WhatsApp Image 2024-09-10 at 18.36.55.jpeg"
        }
    ],
    "data-aerial": [
        {
            "src": "/images/data-aerial/WhatsApp Image 2024-09-10 at 18.57.42.jpeg"
        }
    ]
}

export default function LightboxButton(props) {
    const [open, setOpen] = React.useState(false);

    function toggleOpen() {
        setOpen(!open);
    }

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                {...props}
                sx={{
                    backgroundColor: '#47cf73',
                    color: '#000',
                    borderColor: '#47cf73',
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: '#fff',  // Change to white on hover
                        color: '#000',            // Change text color to black on hover
                        borderColor: '#000',      // Change border color to black on hover
                    },
                    height: props.height
                }}
                onClick={() => toggleOpen()}
            >
                {props.children}
            </Button>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={photos_v2[props.photos]}
                
            />
        </React.Fragment>

    )
}
