import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@mui/material'
import SquareButton from '../components/square-button';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import LightIcon from '@mui/icons-material/Light';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';

import { Fade } from 'react-awesome-reveal';

export default function Experience() {

    return (
        <React.Fragment>

            <Fade delay={100} duration={1500} cascade={true} fraction={0} damping={0.2}>
                <Grid container sx={{ flex: 1, display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' } }}>
                    <Grid item xs={12} md={6} pl={{ md: 10, xs: 2 }} pr={{ md: 10, xs: 2 }} pt={5}>
                        <Box
                            sx={{
                                backgroundImage: 'url("/images/experience-image.jpg")',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                height: '500px'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} pl={{ md: 10, xs: 2 }} pr={{ md: 10, xs: 2 }} pt={5} sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid container sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12}>
                                <Typography variant='h5' color={'#000'}>
                                    ABOUT US
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                <Typography variant='h3' color={'#000'}>
                                    20+ YEARS EXPERIENCE
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                <Typography variant='body1' color={'#000'}>
                                    With 20 years of experience, we have mastered both domestic and commercial electrical systems. Our expertise in troubleshooting and our deep understanding of UK regulations have led to the successful completion of numerous projects, from routine maintenance to large-scale installations. Our commitment to quality and detail has earned us a reputation for reliability and excellence.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginTop: 3 }}>
                                <SquareButton height={45}>Contact Us</SquareButton>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Fade>

            <Grid container sx={{ flex: 1, display: 'flex', marginTop: 6, flexDirection: { md: 'row', xs: 'column-reverse' } }}>
                <Grid item md={6} xs={12} pl={{ md: 10, xs: 2 }} pr={{ md: 5, xs: 2 }} pt={5} sx={{ lex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Fade delay={100} cascade={true} fraction={0} damping={0.5}>
                        <Grid container sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12}>
                                <Typography variant='h5' color={'#000'}>
                                    OUR PURPOSE
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                <Typography variant='h4' color={'#000'}>
                                    "TRANSFORMING HOMES AND BUSINESSES WITH EXPERTLY CRAFTED ELECTRICAL SOLUTIONS"
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', marginTop: 3 }}>
                                <SquareButton height={45}>Contact Us</SquareButton>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
                <Grid item md={6} xs={12} pl={{ md: 10, xs: 2 }} pr={{ md: 5, xs: 2 }} pt={5} sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                    <Fade delay={100} cascade={true} fraction={0} damping={0}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', textAlign: 'center', flexGrow: 1 }}>
                            <Grid item xs={12} sx={{ marginBottom: 3, textAlign: 'center' }}>
                                <Typography variant='h5'>SOME OF OUR DOMESTIC SERVICES</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: 4, textAlign: 'center' }}>
                                <Typography variant='body2'>
                                    We provide a wide range of domestic electrical services, including full house rewiring, fuse board upgrades,
                                    and installation of lighting, sockets, and smart home systems. Whether you're renovating, extending, or need repairs,
                                    our skilled electricians ensure all work meets the highest safety standards. We also offer emergency call-outs, keeping your home
                                    safe and functional. Our goal is to deliver reliable, high-quality electrical solutions for every home.
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                                <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                    <Box sx={{ height: '70px', width: '70px', backgroundColor: '#47cf73', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                                        <ElectricalServicesIcon sx={{ fontSize: 60 }} />
                                    </Box>
                                    <Typography variant='h6'>FULL REWIRE'S</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                                <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                    <Box sx={{ height: '70px', width: '70px', backgroundColor: '#47cf73', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                                        <LightIcon sx={{ fontSize: 60 }} />
                                    </Box>
                                    <Typography variant='h6'>LIGHTING</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                                <Grid container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                    <Box sx={{ height: '70px', width: '70px', backgroundColor: '#47cf73', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                                        <WifiTetheringIcon sx={{ fontSize: 60 }} />
                                    </Box>
                                    <Typography variant='h6'>DATA + AERIAL</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Fade>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}